import { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import './NavigationBar.css';

/**
 * La barre de navigation.
 * @return {jsx}
 */
function NavigationBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav role="navigation">
      <div id="menuToggle">
        {/* Checkbox to control menu visibility */}
        <input type="checkbox" checked={isMenuOpen} onChange={handleMenuToggle} />
        
        {/* Hamburger icon */}
        <span></span>
        <span></span>
        <span></span>

        {/* Menu links */}
        <ul id="menu">
          <Link to="/" onClick={handleLinkClick}><li>Accueil</li></Link>
          <Link to="/about" onClick={handleLinkClick}><li>À Propos</li></Link>
          <Link to="/skills" onClick={handleLinkClick}><li>Compétences</li></Link>
          <Link to="/projects" onClick={handleLinkClick}><li>Mes Projets</li></Link>
          <Link to="/contact" onClick={handleLinkClick}><li>Contact</li></Link>
        </ul>
      </div>
    </nav>
  );
}

export default NavigationBar;
