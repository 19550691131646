import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Cursor from './_misc/Components/Cursor';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
    {/* <div class="custom-cursor"></div>
    <div class="custom-cursor-before"></div> */}
    <Cursor/>

    <nav class="nav_principal">
          <div class="wrapper">
            <ul class="animate">
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
            <li><span class="dot"></span></li>
          </ul>        
        </div>
    </nav> 
      <App />
    </Router>
  </React.StrictMode>
);