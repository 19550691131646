import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import projectsData from '../../data/data.json'; // Assure-toi de mettre le bon chemin
import './FeaturedProjects.css'

const FeaturedProjects = () => {
  const [featuredProjects, setFeaturedProjects] = useState([]);

  useEffect(() => {
    // Filtrer les projets avec les IDs 1, 2 et 3
    const filteredProjects = projectsData.filter(project =>
      [1, 2, 3].includes(project.id)
    );
    setFeaturedProjects(filteredProjects);
  }, []);

  return (
    <Carousel className='carousel'>
      {featuredProjects.map((project) => (
        <Carousel.Item key={project.id}>
          <img
            className="w-100"
            src={project.image}
            alt={project.title}
          />
          <Carousel.Caption>
            <div className='text-project-section'>
                <h4>{project.type}</h4>
                <h3>{project.title}</h3>
                <p>{project.shortDescription}</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default FeaturedProjects;
