import React from 'react';
import WaganeOrdi from '../../assets/images/WaganeOrdi.jpg';
import EcoleSecondaireLogo from '../../assets/images/logo-ecole-secondaire-mgr-a-m-parent.png';
import WaganeGoodVibes from '../../assets/images/WaganeGoodVibes.JPG';
import Infos from '../../assets/images/infos.jpeg';
import VideoIntro from '../../assets/videos/mirroir-intelligent-video.mp4';
import './About.css';

function About() {
    return (
        <>
        <div className='about-section'>
            <section className="histoire fade-in">
                <div className="wrapper">
                <div class="histoires aos-init aos-animate" data-aos="fade-up-right" data-aos-duration="3000"><div class="infosHistoires"><h1>Mon Histoire</h1><p>Bonjour, Moi c’est Mouhammad Wagane Diouf et j’ai 23 ans. Je me décris comme étant une personne ambitieuse, sérieuse, humble et déterminé. Ce que j’aime le plus c’est réfléchir et régler des problèmes. C’est pourquoi j’adore la programmation, celle-ci me permet d'allier réflexion, création et résolution de problème.</p></div><img src="/static/media/WaganeOrdi.099a448e13e18278517b.jpg" alt="Photo_Mouhammad"/></div>

                    <div class="histoires aos-init aos-animate" data-aos="fade-up-left" data-aos-duration="3000">
                        <img src="/static/media/logo-ecole-secondaire-mgr-a-m-parent.b38fd929faa0580d5b24.png" alt="logo-ecole-secondaire-mgr-am-parent"/>
                        <div class="infosHistoires"><p>Au secondaire, je suis allé à l'école secondaire<strong> Monseigneur-Parent.</strong> 
                            Ayant déjà un faible pour les technologies et les mathématiques, je suis entré dans<strong> concentration Science et Technologies.</strong> 
                            C’est dans ces années où j'ai créé mon premier jeu vidéo GameMaker et mes premiers photomontages.</p>
                        </div>
                    </div>

                    <div class="histoires aos-init aos-animate" data-aos="fade-up-right" data-aos-duration="3000"><div class="infosHistoires"><p>J'ai ensuite décidé d'aller au cégep<strong> Édouard-Montpetit</strong> en<strong> technique d’intégration en multimédia.</strong> J'ai suivi ce programme pendant <strong>2 ans</strong> , et à la fin de la 2e année, j'ai pu constater que le multimédia n'était pas exactement ce que je recherchais.</p></div><img src="/static/media/WaganeGoodVibes.4a9d120bfb6120cc5d7f.JPG" alt="Photo_Mouhammad"/></div>
                    
                    <div class="histoires aos-init aos-animate" data-aos="fade-up-left" data-aos-duration="3000"><img src="/static/media/infos.e4354d30a2e11e982ac4.jpeg" alt="logo-ecole-secondaire-mgr-am-parent"/><div class="infosHistoires"><p>Finalement, me voici en<strong> technique de l'informatique</strong> au cégep<strong> Saint-Jean-Sur-Richelieu</strong>. Je suis actuellement en deuxième année de ce programme et j'ai trouvé ma vocation. Je souhaite grandir dans cette profession en tant que futur programmeur et entrepreneur.</p></div></div>
                </div>
            </section>

            <section className="En-Resume fade-in">
                <div className="wrapper">
                    <div className="quiSuisJe">
                        <h1>Quels sont mes atouts ?</h1>
                        <div className="cards">
                            {["Ambitieux", "Sérieux", "Humble", "Déterminé"].map((titre, idx) => (
                                <div key={idx} className="card">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <h2>?</h2>
                                            </div>
                                            <div className="flip-card-back">
                                                <h1>{titre}</h1>
                                                <p>
                                                    {titre === "Ambitieux" && "J'ai des projets à moyen et long terme pour les années à venir."}
                                                    {titre === "Sérieux" && "Au travail, je suis toujours professionnel et appliqué."}
                                                    {titre === "Humble" && "Je n'aime pas vanter les projets que je réalise."}
                                                    {titre === "Déterminé" && "Lorsque j’ai un objectif, je le poursuis jusqu'au bout."}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section class="Objectifs aos-init aos-animate" data-aos="zoom-in-up" data-aos-duration="1000">
                <div class="wrapper">
                    <div class="contentObjectif">
                        <h1>Mes futurs objectifs</h1>
                        <p>Mon objectif principal est de renforcer mes compétences en front-end et back-end en 
                            travaillant sur des projets concrets. Je souhaite collaborer avec des entreprises 
                            technologiques et contribuer à des projets ambitieux.
                        </p>
                    </div>
                </div>
            </section>

            <section class="ProjectNow aos-init aos-animate" data-aos="zoom-in-up" data-aos-duration="1000">
                <div class="wrapper">
                    <div class="contentProjectNow">
                        <h1>Projets du moment</h1>

                        <p>actuellement, je travail sur un projet de multiplateforme de mirroir intelligent. 
                            Ce projet est un projet scolaire qui consiste à créer un mirroir intelligent qui permettra 
                            de voir les informations de la journée en un coup d'oeil. Ce projet est fait en collaboration avec un autre étudiant. </p>

                            <br/>
                            
                            <video controls width="100%">
                            <source src={VideoIntro} type="video/mp4" />
                            Votre navigateur ne prend pas en charge la lecture de vidéos.
                        </video>

                        <br/>

                            <p class="technos">Technologies utilisées: </p>
                            <ul>
                                <li>React</li>
                                <li>React Native</li>
                                <li>Node.js</li>
                                <li>Electron</li>
                                <li>Express.js</li>
                                <li>Docker</li>
                                <li>MySQL</li>
                                <li>API</li>
                                <li>WebSocket</li>
                                <li>Git</li>
                                <li>Nginx</li>
                            </ul>
                        
                    </div>
                </div>
            </section>
            </div>
        </>
    );
}

export default About;
