import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';
import data from '../../data/data.json';

function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects(data);
    }, []);

    return (
        <div className="projects">
            <h1>Projets</h1>
            <div className="projects-list">
                {projects.map(project => (
                    <div key={project.id} className="project">
                        <img src={project.image} alt={project.title} />
                        <h2>{project.title}</h2>
                        <h3>{project.type}</h3>
                        <p>{project.shortDescription}</p>
                        <Link to={`/project/${project.id}`}>
                            <button className='btn-primaire'>En savoir plus</button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;
