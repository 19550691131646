import { ReactTyped } from "react-typed";
import wax from "../../assets/images/wax.jpg";
import './Home.css';
import { Link } from "react-router-dom";
import FeaturedProjects from "../Components/FeaturedProjects";

/**
 *  La page Home.
 * @return {jsx}
 */
function Home() {
    return (
        <>
        <div className="lights-container">
          {[...Array(9)].map((_, i) => (
            <div key={i} className={`light x${i + 1}`}></div>
          ))}
        </div>
        <div className="bg">
            <div className="section-Home">
                <div className="myName"> 
                    <div className="imageMe"></div>
                </div>
                <div className='text-section'>
                    <h1 className="home-title">Mouhammad Wagane Diouf</h1>
                    <ReactTyped
                        strings ={[
                            "Je suis Créateur de site web" ,
                            "Je suis Créateur d'application",
                            "Je suis Développeur Fullstack" ,
                            "Je suis Développeur Front-End" ,
                            "Je suis Développeur Back-End" ,
                        ]}
                        typeSpeed={150}
                        backSpeed={100}
                        loop
                        className="typed-text"
                    />
                </div>
                <h3 className="cv_btn"> 
                    <a className="download-link" href="docs/MouhammadWagane_Diouf_CV.pdf" download>
                        Télécharger mon CV
                    </a>
                </h3>
            </div>
        </div>

        <div className="about-part floating" data-aos="fade-up">
            <img src={wax} alt="Mouhammad"/>
            <div>
                <p><span>Hey !</span> Moi c’est Mouhammad et j’ai 23 ans. Je suis un passionné de la programmation web, application mobile et d'application de bureau. Je suis actuellement étudiant en technique de l'informatique au cégep Saint-Jean-Sur-Richelieu. Si vous cherchez quelqu’un d’ambitieux et de bonne humeur, je suis votre homme</p>
                <Link to={`/about`}>
                    <button className="btn-primaire">En savoir plus</button>
                </Link>
            </div>
        </div>

        <div className="featured-projects-section floating" data-aos="fade-right" data-aos-duration="3000">
            <h1>Mes Projets <span className="span-subtitle-feature-project">En Vedette</span></h1>
            <FeaturedProjects />
            <Link to={`/projects`}>
                <button className="btn-primaire">Voir les projets</button>
            </Link>
        </div>
    </>
    )
}

export default Home;
